const yttc200 = {
  rates: {
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  dates: ["3 January to 26 January 2024"],
};

const yttc100hathaAshVin = {
  rates: {
    private: "EUR 900",
    shared: "EUR 650",
  },
  dates: [
    "28 November to 9 December 2023",
    "22 December to 2 January 2024",
    "15 January to 26 January 2024",
  ],
};

const yttc100AerialYin = {
  rates: {
    private: "EUR 1100",
    shared: "EUR 800",
  },
  dates: [
    "16 November to 27 November 2023",
    "10 December to 21 December 2023",
    "3 January to 14 January 2024",
  ],
};

const yttc50Hatha = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "15 September to 22 September 2024",
    "06 October to 13 October 2024",
    "27 October to 03 November 2024",
    "08 December to 15 December 2024",
    "29 December to 05 January 2025",
    "19 December to 26 January 2025",
    "09 Febuary to 16 Febuary 2025",
    "02 March to 09 March 2025",
    "23 March to 30 March 2025",
    "13 April to 20 April 2025",
    "04 May to 11 May 2025",
    "25 May to 01 June 2025",
    "15 June to 22 June 2025",
  ],
};

const yttc50AerialAcro = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "29 September to 06 October 2024",
    "20 October to 27 October 2024",
    "10 November to 17 November 2024",
    "01 December to 08 December 2024",
    "22 December to 29 December 2024",
    "12 January to 19 January 2025",
    "02 Febuary to 09 Febuary 2025",
    "23 Febuary to 02 March 2025",
    "16 March to 23 March 2025",
    "06 April to 13 April 2025",
    "27 April to 04 May 2025",
    "18 May to 25 May 2025",
    "08 June to 15 June 2025",
    "29 June to 06 July 2025",
  ],
};

const yttc50Ashtanga = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "22 September to 29 September 2024",
    "01 October to 20 October 2024",
    "03 November to 10 November 2024",
    "24 November to 01 December 2024",
    "15 December to 22 December 2024",
    "05 January to 12 January 2025",
    "26 January to 02 Febuary 2025",
    "16 Febuary to 23 Febuary 2025",
    "09 March to 16 March 2025",
    "30 March to 06 April 2025",
    "20 April to 27 April 2025",
    "11 May to 18 May 2025",
    "01 June to 08 June 2025",
    "22 June to 29 June 2025",
  ],
};

const yttc15days100HathaAshtanga = {
  rates: {
    private: "EUR 1200",
    shared: "EUR 900",
  },
  dates: [
    "15 September to 29 September 2024",
    "6th October to 20 October 2024",
    "27th October to 10 November 2024",
    "17 November to 01 December 2024",
    "08 December to 22 December 2024",
    "29 December to 11 January 2025",
    "19 January to 02 Feburary 2025",
    "09 Febuary to 23 Feburary 2025",
    "02 March to 16 March 2025",
    "23 March to 06 April 2025",
    "13 April to 27 April 2025",
    "04 May to 18 May 2025",
    "25 May to 08 June 2025",
    "15 June to 29 June 2025",
  ],
};

const yttc15days100AshtangaAerial = {
  rates: {
    private: "EUR 1200",
    shared: "EUR 900",
  },
  dates: [
    "22 September to 06 October 2024",
    "13 October to 27 October 2024",
    "03 November to 17 November 2024",
    "24 November to 08 December 2024",
    "15 December to 29 December 2024",
    "05 January to 19 January 2025",
    "26 January to 09 Feburary 2025",
    "16 Feburary to 02 March 2025",
    "09 March to 23 March 2025",
    "30 March to 13 April 2025",
    "20 April to 04 May 2025",
    "11 May to 25 May 2025",
    "01 June to 15 June 2025",
  ],
};

const yttc22days200HathaAshtangaAerialAcro = {
  rates: {
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  dates: [
    "08 September to 29 September 2024",
    "15 September to 06 October 2024",
    "22 September to 13 October 2024",
    "29 September to 20 October 2024",
    "06 October to 27 October 2024",
    "13 October to 3 November 2024",
    "20 October to 10 November 2024",
    "27 October to 17 November 2024",
    "3 November to 24 November 2024",
    "10 November to 1st December 2024",
    "17 November to 8 December 2024",
    "24 November to 15 December 2024",
    "1 December to 22 December 2024",
    "8 December to 29 December 2024",
    "15 December to 5 Janauary 2025",
    "22nd December to 12 January 2025",
    "29 December 2024 to 19 January 2025",
    "5 January to 26 January 2025",
    "12 January to 2nd February 2025",
    "19 January to 9 February 2025",
    "26 January to 16 February 2025",
    "2nd February to 23 February 2025",
    "9 February to 2 March 2025",
    "16 February to 9 March 2025",
    "2 February to 16 March 2025",
    "2 March to 23 March 2025",
    "9th March to 30 March 2025",
    "16 March to 6 April 2025",
    "23 March to 13 April 2025",
    "30 March to 20 April 2025",
    "6 April to 27 April 2025",
  ],
};

const yogaHolidaysBaliDates = {
  rates: {
    private: "EUR 700",
    shared: "EUR 500",
  },
  dates: ["Custom Dates, enter your dates in Special request section"],
};

const yttc200Bali200Puri = {
  rates: {
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  dates: [
    "29 September 20 October 2024",
    "20 October 2024 10 November 2024",
    "10 November 1 December 2024",
    "1 December 22 December 2024",
  ],
};

export {
  yttc200,
  yttc100hathaAshVin,
  yttc100AerialYin,
  yttc50Hatha,
  yttc50AerialAcro,
  yttc50Ashtanga,
  yttc15days100HathaAshtanga,
  yttc15days100AshtangaAerial,
  yttc22days200HathaAshtangaAerialAcro,
  yogaHolidaysBaliDates,
  yttc200Bali200Puri,
};
